(function ($) {
  $(window).load(function () {
    // owl-carousel
    $(".owl-carousel:not(.slider)").owlCarousel({
      items: 1,
      loop: true,

      // autoplay
      autoplay: true,
      autoplayTimeout: 7000,

      // speed
      smartSpeed: 500,

      // animation
      animateIn: "fadeIn",
      animateOut: "fadeOut",

      // navigation
      nav: true,
      navText: [
        '<i class="fas fa-chevron-left"></i>',
        '<i class="fas fa-chevron-right"></i>',
      ],
      dots: true,

      // drag
      mouseDrag: false,
    });

    // slider
    $(".slider").owlCarousel({
      items: 1,
      margin: 15,
      loop: true,

      // autoplay
      autoplay: true,
      autoplayTimeout: 7000,

      // speed
      smartSpeed: 500,

      // navigation
      nav: true,
      navText: [
        '<i class="fas fa-chevron-left"></i>',
        '<i class="fas fa-chevron-right"></i>',
      ],
      dots: true,

      // drag
      mouseDrag: true,

      // responsive
      responsive: {
        0: {
          items: 1,
        },
        576: {
          items: 2,
        },
        768: {
          items: 2,
        },
        992: {
          items: 3,
        },
        1200: {
          items: 3,
        },
      },
    });

    // form-sent
    setTimeout(() => {
      var locationSearch = "" + document.location.search;
      if (
        (-1 === locationSearch.indexOf("form[sent]") &&
          -1 === decodeURIComponent(locationSearch).indexOf("form[sent]")) ||
        -1 === document.location.hash.indexOf("#form")
      ) {
        return;
      }

      var $headerHeight = $(".header");
      var $message = $(".form-success-message");

      if (!$headerHeight.length || !$message.length) {
        return;
      }

      var currentScrollTop = $("html").scrollTop();
      var newScrollTop =
        $message.offset().top - $headerHeight.outerHeight() - 30;
      if (newScrollTop >= currentScrollTop) {
        return;
      }

      $("html, body").animate({
        scrollTop: $message.offset().top - $headerHeight.outerHeight() - 30,
      });
    }, 500);
  });

  $(document).ready(function () {
    // sticky
    require("../../vendor/w3media/framework/assets/js/V2/body-sticky-class");

    // fancybox
    $("a[data-fancybox]").fancybox({
      buttons: [
        //"zoom",
        //"share",
        //"slideShow",
        //"fullScreen",
        //"download",
        "thumbs",
        "close",
      ],
    });

    // scroll-to-content
    $(".js-3w-scroll-to-content").on("click", function (event) {
      event.preventDefault();
      var identifier = $(this).attr("data-target");
      var $element = $(identifier);

      if ($element) {
        $("html, body").animate({ scrollTop: $element.offset().top }, 500);
      }
    });
  });
})(jQuery);

// mobile sticky header
let header = document.querySelector("header");
let toggler = document.querySelector(".navbar-toggler");

if (header && toggler) {
  header.classList.remove("mobile-sticky");
  window.document.addEventListener("scroll", () => {
    if (window.scrollY !== 0) {
      header.classList.add("mobile-sticky");
    } else if (window.scrollY === 0 && toggler.ariaExpanded !== "true") {
      header.classList.remove("mobile-sticky");
    }
  });

  toggler.addEventListener("click", () => {
    let status = toggler.ariaExpanded;
    if (status === "false") {
      header.classList.add("mobile-sticky");
    }
    if (status === "true" && window.scrollY === 0) {
      header.classList.remove("mobile-sticky");
    }
  });
}



// switch domain mobile

if (screen.width < 1200){
let items = document.querySelectorAll('ul.fast-links li');
let target = document.querySelector('.menu');




let selectDomain = document.createElement('div')
selectDomain.classList.add('domains');



items.forEach(item => {

let image = item.querySelector('img').src
let link = item.querySelector('a').href


let newOption = document.createElement('a');
newOption.href = link;
newOption.innerHTML = `<img src="${image}"/>`

selectDomain.append(newOption);
});





target.append(selectDomain);
}
